
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import Textarea from "@/components/Textarea"
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  CameraStatus,
} from "@evercam/shared/types"

export default {
  meta: {
    pageId: AnalyticsEventPageId.EmbeddedLive,
  },
  components: {
    RecordingsWidget,
    CamerasAutocomplete,
    Textarea,
  },
  data() {
    return {
      resolution: "%",
      secondsToNextRefresh: 12,
      width: 100,
      selectedPreAuth: false,
      playPause: true,
      zoom: true,
      fullscreen: false,
      userApiId: "API_ID",
      userApiKey: "API_KEY",
      download: false,
      resolutionType: [
        { text: "%", value: "%" },
        { text: "px", value: "px" },
      ],
      selectedCamera: null,
    }
  },
  head() {
    return {
      title: "Evercam | Live View Widget",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore, useAccountStore),
    embedCode() {
      if (!this.selectedCamera) {
        return ""
      }

      let preAuth = ""
      if (this.selectedPreAuth) {
        preAuth = !this.$permissions.user.is.admin()
          ? `&api_id=${this.userApiId}&api_key=${this.userApiKey}`
          : "&api_id=API_ID&api_key=API_KEY"
      }

      const isPrivate = !this.selectedCamera?.isPublic

      // eslint-disable-next-line no-useless-escape
      return `<div id='ec-container-${this.selectedCamera?.id}' style='width:${this.width}${this.resolution};'></div> <script src='${window.location.origin}/liveViewWidget.js?refresh=${this.secondsToNextRefresh}&camera=${this.selectedCamera?.id}&private=${isPrivate}${preAuth}&playpause=${this.playPause}&zoom=${this.zoom}&download=${this.download}&fullscreen=${this.fullscreen}' async><\/script>`
    },
    isPublicCamera() {
      return this.selectedCamera.isPublic
    },
    embeddedLiveViewKey() {
      return `${(this.selectedCamera || this.getDefaultCamera())?.id}-${
        this.userApiId
      }`
    },
    cameraId() {
      return `${(this.selectedCamera || this.getDefaultCamera())?.id}`
    },
  },
  watch: {
    selectedCamera(camera) {
      this.updatePreAuthStatus()
      this.getCustomRefreshRateSeconds(camera?.cloudRecordings?.frequency)
    },
  },
  created() {
    this.selectedCamera = this.getDefaultCamera()
    this.getCustomRefreshRateSeconds(
      this.selectedCamera?.cloudRecordings?.frequency
    )
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)

    if (!this.accountStore.apiId || !this.accountStore.apiKey) {
      await this.accountStore.fetchUserCredentials()
    }
    this.updatePreAuthStatus()
    this.userApiId = this.accountStore.apiId
    this.userApiKey = this.accountStore.apiKey
  },
  methods: {
    getDefaultCamera() {
      const cameras = this.cameraStore.cameras?.filter(
        (camera) => camera.status !== CameraStatus.Decommissioned
      )

      return cameras?.length ? cameras[0] : {}
    },
    onChangeCamera(camera) {
      this.selectedCamera = camera ? camera : this.getDefaultCamera()
      this.updatePreAuthStatus()
      this.getCustomRefreshRateSeconds(camera?.cloudRecordings?.frequency)
    },
    updatePreAuthStatus() {
      this.selectedPreAuth = !this.isPublicCamera
    },
    getCustomRefreshRateSeconds(frequency) {
      this.secondsToNextRefresh = parseInt(
        [1, 5, 10].includes(frequency) ? frequency * 60 : 60 / frequency
      )
    },
    filterCameras(cameras) {
      return cameras?.filter(
        (camera) => camera.status !== CameraStatus.Decommissioned
      )
    },
  },
}
